/** @jsx jsx */
import { jsx, Heading, Box } from "theme-ui"
import * as React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from "../components/title"

const AboutPageTemplate = ({ data, location }) => {
  const {
    mdx: post,
    site: {
      siteMetadata: { title },
    },
  } = data

  return (
    <>
      <Layout location={location} title={title} wrapper="global-wrapper">
        <Title text=""></Title>
        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description}
        />
        <article
          className="article"
          itemScope
          itemType="http://schema.org/Article"
        >
          <Box variant="postHeader">
            <Box variant="postHeaderContent">
              <p>Hi, I'm Andrew</p>
            </Box>
          </Box>
          <Box variant="wrapper.home">
            <MDXRenderer>{post.body}</MDXRenderer>
          </Box>
        </article>
      </Layout>
    </>
  )
}

export default AboutPageTemplate

export const aboutPageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        description
      }
    }
  }
`
