/** @jsx jsx */
import * as React from "react"
import { jsx, Box } from "theme-ui"

const Title = ({ text, as = `h2`, className = `` }) => (
  <div
    sx={{
      justifyContent: `space-between`,
      alignItems: `center`,
      borderBottomStyle: `none`,
      borderBottomWidth: `0.5px`,
      borderBottomColor: `divider`,
      pt: `24px`,
      pb: 2,
      ml: `2rem`,
      mb: 4,
      flexFlow: `wrap`,
      boxSizing: `border-box`,
      display: `flex`,
    }}
  >
    <Box as={as} variant="text.heading" className={className}>
      {text}
    </Box>
  </div>
)

export default Title
